<template>
  <div class="flex_column tenantList">
    <dog-search
      ref="search"
      :config="searchConfig"
      @search="search"
    ></dog-search>
    <dog-table
      ref="dogTable"
      id="6221517a-f2de-4491-b075-9189107378fe"
      :columns="tableColumns"
      :service="getList"
      column-type="selection"
      @selection-change="selectedData = $event"
    >
      <permission-button slot="btn" :config="btnConfig"> </permission-button>
    </dog-table>
    <set-company-dialog
      ref="setCompanyDialog"
      @confirm="searchData"
    ></set-company-dialog>
    <add-tenant-dialog
      ref="addTenantDialog"
      @confirm="searchData"
    ></add-tenant-dialog>
  </div>
</template>

<script>
  import tenantService from '@/api/service/tenant';
  import setCompanyDialog from './setCompanyDialog.vue';
  import addTenantDialog from './addTenantDialog.vue';

  export default {
    name: 'tenantList',
    components: { setCompanyDialog, addTenantDialog },
    data() {
      return {
        searchConfig: [
          {
            type: 'input',
            label: '用户名',
            key: 'userName'
          },
          {
            type: 'input',
            label: '真实姓名',
            key: 'realName'
          }
        ],
        tableColumns: [
          {
            prop: 'operate',
            label: '操作',
            width: 100,
            widthGrow: false,
            render: (h, { props: { row } }) => {
              return (
                <permission-element
                  config={[
                    {
                      code: 'setCompanies',
                      callback: () =>
                        this.$refs.setCompanyDialog.openFrame(row.id)
                    }
                  ]}></permission-element>
              );
            }
          },
          { prop: 'userName', label: '用户名', width: 100, widthGrow: false },
          { prop: 'realName', label: '真实姓名', width: 80, widthGrow: false },
          { prop: 'sex_cn', label: '性别', width: 50, widthGrow: false },
          {
            prop: 'avatar',
            label: '头像',
            width: 60,
            widthGrow: false,
            render: (h, { props: { row } }) => {
              return row.avatar ? (
                <el-avatar src={row.avatar} size={20}></el-avatar>
              ) : (
                <el-avatar icon='el-icon-user-solid' size={20}></el-avatar>
              );
            }
          },
          { prop: 'remark', label: '个人简介', width: 200 }
        ],
        btnConfig: [
          {
            buttonType: 'primary',
            code: 'addTenants',
            callback: () => this.$refs.addTenantDialog.openFrame()
          },
          {
            buttonType: 'danger',
            code: 'deleteTenants',
            callback: this.deleteTenants
          }
        ],
        selectedData: []
      };
    },
    mounted() {
      this.searchData();
    },
    methods: {
      searchData() {
        this.$refs.search.search();
      },
      search(query) {
        this.$refs.dogTable.search(query);
      },
      getList(params) {
        return tenantService.getTenantList(params);
      },
      deleteTenants() {
        if (this.selectedData.length === 0) {
          return this.$message.error('请至少选择一条数据！');
        }
        this.$confirm(`是否确定删除所选数据？`, '提示', {
          type: 'warning'
        }).then(() => {
          tenantService
            .deleteTenants({ ids: this.selectedData.map((item) => item.id) })
            .then(this.searchData);
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  /deep/ .el-avatar {
    vertical-align: middle;
  }
</style>
